
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed var(--#{$prefix}border-color) !important;
  background: var(--#{$prefix}custom-white) !important;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
  >.dropzone.dz-wrapper{
    border: none !important;
  }
}